
.note-details{
  min-width: 30vw;
  height: 100%;
  border-right: 1px solid var(--border-color);
  min-height: 20vh;
  margin: 5px;
}

.note-text {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--secondary-color);
  min-width: 20vw;
  min-height: 100px;
  margin: 0 0 10px 10px;
  overflow: auto;
  max-width: 100%;
  max-height: 80%;
  border: 1px solid var(--border-color);

}
.note-details textarea {
  resize: none;
  flex: 1;
  width: 90%;
  min-height: 50%;
}

.bid-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 20vh;
  margin-left: 20px;
}
.bid-details-header {
  width: 100%;
  flex: 1.5;

}
.bid-details-header h3 {
  text-align: left;
}

.bid-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
}

.bid-details textarea {
  flex: 1.5;
  margin: 5px;
  width: 90%;
  resize: none;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-color);
  min-height: 100px;
  overflow-y: auto;
}

.interference-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.bid-review-container {
  background-color: var(--secondary-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
}

