.comments-section {
    max-width: 800px;
    min-width: 400px;
    margin: 0 auto;
    padding: 20px;
}
.form-container {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-top: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
.comment-input {
    flex-grow: 1;
    padding: 10px 15px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    outline: none;
}
.text-submit-button {
    padding: 10px 15px;
    margin: 0 0 5px 5px;
    background-color: var(--button-color);
    color: var(--text-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
}

.text-submit-button:hover {
    background-color: var(--primary-color);
}

.comment {
    display: flex;
    margin-bottom: 10px;
}

.comment-bubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 15px;
    margin: 5px;
}

.right .comment-bubble {
    background-color: #DCF8C6; /* Green bubble for current user */
    margin-left: auto;
    border-bottom-right-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left .comment-bubble {
    background-color: var(--primary-color); /* Blue bubble for others */
    border-bottom-left-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.comment-info {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 5px;
}

.comment-input,
.submit-button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}