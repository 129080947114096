.selected-convention {
    background-color: var(--border-color); /* Change this to your preferred color */
}
.agreement-box {
    padding: 5px;
    margin-left: 10px;
}
.agreement-button {
    padding: 10px;
    font-size: 14px;
}
.email-button {
    padding: 4px;
    margin: 0 0 0 30px;
    font-size: 12px;
}
.accept-button, .decline-button {
    padding: 4px;
    margin: 0 0 0 10px;
    font-size: 12px;
}
.accept-button {
    color: green;
}
.decline-button {
    color: red;
}
.invite-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 2px;
    padding: 2px;
}

.agreement-item{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin: 2px;
    padding: 2px;
}
.add-dialog input {
    margin: 5px;
    padding: 8px;
    font-size: 14px;
}
.email-dialog input {
    margin: 5px;
    padding: 8px;
    font-size: 14px;
}

.bid-container {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    transition: all 0.5s ease;
    max-height: 350px;
    min-height: 200px;
}


.bids {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    flex-grow: 2;


}
.bid-tags {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    font-size: 10px;
}
.bid-options{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.bid-path .spacer {
    flex-grow: 1;
}

.parent-bids {
    max-width: calc(0.8 * 500px);
    margin: 5px;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 35px;

}

.current-bids, .next-bids {
    flex: 1;
    max-width: 500px;
    margin: 5px 5px 0 0;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 35px;

}
.add-bid-button {
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    align-items: center;

    /* ... existing styles ... */
}

.bid-box {
    position: relative;
    padding: 3px 0 0 0;
    border-radius: 10px;
    margin: 5px;
    width: 300px;
    min-height: 60px;

}
.bid-box h5 {
    margin: 0;
    position: absolute;
    padding: 0 0 2px 0;
    top: 0;
    left: 0;
    border-radius: 5px 10px 0 0;
    width: 100%;
    background-color: var(--accent-color);
}
.bid-box h5 span {
    display: inline-block;
    padding-left: 10px; /* Adjust this value to move the text over */
    padding-top: 2px;
}
.bid {
    border: 1px solid var(--border-color);
    top: 17px;
    bottom: 13px;
    cursor: pointer;
    width: 100%;
    border-radius: 0 0 10px 10px;
    position: relative;
    background-color: var(--primary-color);
}
.bid-box p {
    margin: 2px;
    padding: 0;
}



.bid.selected {
    border: 2px solid var(--accent-color);; /* Example of different styling for selected bid */
    /* Other styles for the selected bid box */
}

.empty-message {
    /* styles for the empty message */
    text-align: center;
    /* other styling */
}

.highlighted {
    background-color: var(--highlighter-color);
}

.deleted-style {
    background: repeating-linear-gradient(
            45deg,
            #f0f0f0,
            #f0f0f0 10px,
            #cccccc 10px,
            #cccccc 20px
    );
    color: #808080; /* Gray text */
}
.highlighted.deleted-style {
    background: repeating-linear-gradient(
            45deg,
            var(--highlighter-color),
            var(--highlighter-color) 10px,
            #cccccc 10px,
            #cccccc 20px
    );
    color: #808080; /* Gray text */
}


/* Add Bid Popup CSS */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-bid-popup {
    position: absolute;
    z-index: 1000;
    /* Add your preferred width and height */
    /* Add background color and padding for visibility */
    background-color: white;
    padding: 20px;
    /* Add border radius if you want the popup corners to be rounded */
    border-radius: 10px;
}

.popup-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin: 10px 10px 10px 0;
}
.add-bid-popup {
    background-color: #fff; /* Change to your preferred color */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px; /* Adjust as needed */
    margin: auto;
}

.add-bid-popup form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.add-bid-popup label {
    font-weight: bold;
    margin-bottom: 5px;
}

.add-bid-popup input[type="text"],
.add-bid-popup select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 4px;
    width: 90%;
}

.add-bid-popup input[type="checkbox"] {
    margin-right: 5px;
}

.popup-buttons{
    color: var(--button-color);
    padding: 5px;
    display: flex;
    margin: 5px;
    justify-content: space-between;
}
.popup-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.popup-checkboxes label {
    display: flex;
    align-items: center;
}

.popup-points {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 5px;
}
.popup-points input {
    width: 40px;
    margin: 5px;
    padding: 2px;
}

.add-bid-button {
    width: 15px; /* Adjust as needed */
    height: 17px; /* Adjust as needed */
    align-items: center;
    padding: 2px;
    border-radius: 0;
    line-height: 1;

    /* ... existing styles ... */
}
.update-bid-button {
    width: 20px; /* Adjust as needed */
    height: 18px; /* Adjust as needed */
    padding: 2px;
    background-color: transparent;
    line-height: 1;
    margin: 0 3px 0 3px;


    /* ... existing styles ... */
}
.update-bid-button:hover {
    background-color: var(--button-color) /* A slightly darker shade for hover */
}



/* Lot of code for a toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 35px; /* Adjusted to 3/4 of the original width */
    height: 17px; /* Adjusted to 1/2 of the original height */
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(19px); /* Adjusted to 3/4 of the original translation */
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.bid-toggle {
    margin-top: 5px;
    padding: 5px;
}
.bid-toggle div {
    font-size: 10px;
}
.bid-toggle h5 {
    font-size: 12px;
    margin: 0;
    padding: 2px;
}

.small-text {
    font-size: 10px;
}

