@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: var(--secondary-color);
    background-image: url("../images/ourbridge_bids_header.png");
    background-size: contain;
    position: fixed;
    height: 90px;
    top: 0;
    width: 100%;
    z-index: 100;
    /* Add additional styling as needed */
}

.header-left {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    gap: 10px; /* Adds space between items */
}
.header-logo {
    height : 100px;

}
.header h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    color: var(--text-color);
    line-height: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 1px black;
}

.header-right {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px; /* Adds space between items */
    margin: 10px;
    /* Additional styling as needed */
}

.login-dropdown {
    position: absolute;
    top: 100%; /* Position below the header */
    right: 0;
    background-color: var(--secondary-color);
    border: 1px solid var(--border-color);
    z-index: 1000;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

button {
    background-color: var(--button-color);
    color: var(--text-color);
    border: None;
    padding: 10px 20px; /* Top/bottom and left/right padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s; /* Smooth transition for hover effect */
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

button:hover {
    background-color: var(--primary-color);
    color: var(--text-color);

}

.intro-text {
    font-size: 20px;
    color: var(--text-color);
    line-height: 1;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}