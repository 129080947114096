.notifications-container {
  position: fixed;
  right: 0;
  top: 25%;
  width: 240px; /* Adjust width to accommodate the bell icon */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
}

.notification-header {
  text-align: center;
  margin: 5px;
  font-size: 12px;
}
.dismiss-all-button{
  position: absolute;
  bottom: 5px;
  left: 5px;
  border: none;
  cursor: pointer;
  color: black;
  width: 60px;
  height: 25px;
  font-size: 10px;
  margin: 0;
  padding: 0;
}

.notification-text {
  border: var(--border-color) solid 1px;
  border-radius: 5px;
}
.notification-text.bold {
  font-weight: bold;
  border: var(--border-color) solid 1px;
  border-radius: 5px;
}

.delete-button {
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin: 0;
  padding: 0;
}


.bell-icon {
  position: absolute;
  left: -60px;
  top: 50%;
  width: 40px;
  height: 40px;
  border: var(--border-color) solid 1px;
  background: url('../images/bell_icon.png') no-repeat center;
  background-size: contain;
  opacity: 0.6;
  cursor: pointer;;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-panel {
  width: 220px;
  height: 40vh;
  background: var(--accent-color);
  border: var(--border-color) solid 2px;
  font-size: 11px;
  padding: 20px;
  overflow-y: auto;
  opacity: 0.75;
  border-radius: 10px;
}

.show {
  transform: translateX(-40px); /* Adjust this value to control the slide distance */
}

.notification {
  padding: 2px;
  border-radius: 5px;
  margin-top: 2px;
}

.notification button {
  float: right;
}

.notifications-panel-header {
  text-align: center;
  font-size: 12px;
  border-bottom: var(--border-color) solid 2px;
}