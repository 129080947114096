
:root {
  --primary-color: #4da6ff; /* Lighter Blue, for primary elements */
  --secondary-color: #f0f8ff; /* Alice Blue, for backgrounds */
  --accent-color: #5c85d6; /* Soft Blue, for accents */
  --text-color: #333333; /* Dark Grey, for text */
  --border-color: #b3c6ff; /* Very Light Blue, for borders */
  --error-color: #e74c3c; /* Soft Red, for errors */
  --button-color: #F5F5DC; /* Bright Blue, for buttons */
  --selected-button-color: #1a75ff; /* Slightly Darker Blue, for selected buttons */
  --hover-color: black; /* Very Pale Blue, for hover states */
  --highlighter-color: #ffff00; /* Yellow, for highlighting */
  background-color: var(--secondary-color);
}
.App {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
}
.App-link {
  color: #61dafb;
}
.landing-page {
  background-image: url("./images/ourbridge_bids_background.png") ;
  background-size: cover;
  flex: 1;
  min-height: 92vh;
  margin-top: 100px;
}
.landing-page-text {
  margin: 10px;
  color: var(--text-color)
}
.feature-logo {
  width: 30px;
  height: 30px;
  margin: 5px;
}
.footer-logo {
  width: 100px;
  height: 100px;
  margin: 5px;
}
.donate-button {
  margin-left: 10px;
}
.email-activation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

/*Login CSS */
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  max-height: 35vh;
  max-width: 20vw;
  width: 270px;
}
.login-form-container h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.login-form-fields input {
  margin: 2px;
  padding: 5px;
  font-size: 16px;
  width: 100%;
}
.error-message {
  color: var(--error-color);
  font-size: 12px;
  margin: 0;
  padding: 0;
}


/* Main Body CSS */
.main-body {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}
.agreement-bid-box {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 30vh; /* One third of the parent container's height */
}

.agreement-container {
  flex: 1; /* Takes up 1 part */
  border: 1px solid var(--border-color);
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  max-height: 350px;

}

.bid-wrapper {
  display: flex;
  flex: 5;
  flex-direction: column; /* Stack children vertically */

}

.bid-notes-box {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.notes-details-box {
  flex: 1;
  min-height: 25vh;
}
.bids-details-box{
  flex: 2;
  min-height: 25vh;
}
.audit-details-box {
  flex: 1;
  min-height: 25vh;
}

.comments-box {
  display: flex;
  width: 50%; /* or any other width */
  margin: 0 auto; /* auto left and right margins */
  justify-content: center;
  align-items: center;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
  border: 3px solid var(--secondary-color);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);
}

.forgot-password-form-container {
  position: fixed; /* or absolute */
  color: var(--text-color);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.forgot-password-form-container input {
  margin: 2px;
  padding: 5px;
  font-size: 16px;
  width: 100%;
}
.privacy-policy-container {
  margin: 100px 10px;
  color: var(--text-color);
}

.features {
  margin: 10px;
}

.footer {
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;
  border-top: var(--border-color);
}



